import React from 'react'

import Container from '../components/Container'

import { areas } from '../assets/js/areas'
import './Areas.scss'

const Areas = () =>
  <section className='Areas'>
    <Container className='Areas--container'>
      <h2>Service Areas</h2>
      <div className='Areas--container--card--list'>
        {areas.map((a, i) => <span key={i}>{a}</span>)}
      </div>
      <span>and surrounding areas</span>
    </Container>
  </section>

export default Areas