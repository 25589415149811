import React from 'react'

import Layout from '../components/Layout'
import Container from '../components/Container'
import Areas from '../components/Areas'
import Link from '../components/Link'
import Card from '../components/Card'

import phone from '../assets/img/icons/phone.svg'
import email from '../assets/img/icons/email.svg'

import './Contact.scss'

const Contact = ({location}) =>
  <Layout
    location={location}
    title='Pawsome Dogs | Chicago Dog Training | Contact'
    description='Follow Pawsome Dogs on Facebook for the latest news and updates. Get in touch with us to schedule your first lesson!'>
    <main className='Contact'>

      <Container className='Contact--container'>

        <div className='Contact--container--one'>
          <h2>Ready to schedule your first lesson? Please fill out our contact form.</h2>
          <Link to='/Form' cta>Contact Form</Link>
        </div>

        <div className='Contact--container--two'>
          <h2>Questions?</h2>
          <p>Feel free to reach out to us and we will get back to you as soon as possible.</p>
          <ul className='Contact--container--list'>
            <li><img src={phone} alt='phone contact' />Call us at&nbsp;<a href='tel:773-818-7204' target='_top'>773-818-7204.</a></li>
            <li><img src={email} alt='email contact' />Email us at&nbsp;<a href='mailto:melissa@pawsomedogs.net' target='_top'>melissa@pawsomedogs.net</a>.</li>
          </ul>
        </div>

        <Card className='Contact--wrapper'>

          <div className='Contact--container--fb'>
            <h2>Find us on Facebook</h2>
            <iframe
              title='mobile'
              className='mobile'
              width='300'
              height='500'
              src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPawsomeDogs%2F&tabs=timeline&width=300&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true'
              scrolling='no'
              frameBorder='0'>
            </iframe>
            <iframe
              title='tablet'
              className='tablet'
              width='375'
              height='500'
              src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPawsomeDogs%2F&tabs=timeline&width=375&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true'
              scrolling='no'
              frameBorder='0'>
            </iframe>
            <iframe
              title='computer'
              className='computer'
              width='500'
              height='500'
              src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPawsomeDogs%2F&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true'
              scrolling='no'
              frameBorder='0'>
            </iframe>
          </div>

          <Areas />

        </Card>

      </Container>
    </main>
  </Layout>

export default Contact