const areas = [
  'Old Irving Park',
  'Mayfair',
  'Albany Park',
  'Lincoln Square',
  'North Center',
  'Ravenswood',
  'Roscoe Village',
  'Andersonville',
  'Lakeview',
  'Lincoln Park',
  'Logan Square',
  'Bucktown',
  'Wicker Park',
  'Uptown',
  'Sauganash',
  'Edgebrook',
  'Jefferson Park',
  'Portage Park',
]

export { areas }
